<template>
  <v-app>
    <v-snackbar
        v-model="showSnackBar"
        :color="snackBarColor"
        dark
        app
        width="100vw"
    >
      {{ snackBarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            dark
            text
            @click="showSnackBar = false"
            v-bind="attrs"
        >
          <v-icon>mdi-close</v-icon>
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
    <v-row align="center" justify="center" @click="$router.push({name:'Home'})">
      <h1>PRIVATE</h1>
      <v-img
          alt="Vuetify Name"
          class="shrink mt-1 ml-1 mr-1"
          contain
          min-width="10"
          src="~@/assets/logo-transparent.png"
          width="54"
          @click="$router.push({name:'Home'})"
      />
        <h1>GAMES</h1>
    </v-row>
    <v-main fill-height class="black">
      <v-row no-gutters align-content="center" justify="center">
        <v-container :fluid="$vuetify.breakpoint.mdAndUp" class="pa-0">
          <router-view/>
        </v-container>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import AuthAdminNeeded from "../mixins/AuthAdminNeeded";
import MessageMixin from "../mixins/MessageMixin";

export default {
  name: "AuthLayout",
  components: {},
  mixins: [AuthAdminNeeded, MessageMixin]
}
</script>

<style scoped>

</style>